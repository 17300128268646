import * as React from "react";
import {
  Accordion as ChakraAccordion
} from "@chakra-ui/react"

import {
  FrequentlyAskedQuestion
} from "./Items";


interface Props {
  nodes: any[]
  layout: string
}


export const Accordion: React.FC<Props> = ( props ) => {
  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.layout ) {
      case 'faq':
        return (
          <FrequentlyAskedQuestion
            key={ _index }
            node={ node.node }
          />
        )
      default:
        return null;
    }
  });

  return (
    <ChakraAccordion
      allowToggle
    >
      { nodeList }
    </ChakraAccordion>
  )
}
