import * as React from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Text
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const FrequentlyAskedQuestion: React.FC<Props> = ( props ) => {
  return (
    <AccordionItem>
      <AccordionButton
        padding="16px 0"
        _hover={{
          background: 'transparent'
        }}
      >
        <Heading
          as="h3"
          flex="1"
          color="text.primary"
          fontSize="20px"
          fontWeight={ 700 }
          textAlign="left"
        >
          { props.node.frontmatter.question }
        </Heading>
        <AccordionIcon
          color="primary.900"
        />
      </AccordionButton>
      <AccordionPanel
        padding="16px 0"
      >
        <Text
          color="text.secondary"
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.node.frontmatter.answer }
        </Text>
      </AccordionPanel>
    </AccordionItem>
  )
}
