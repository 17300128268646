import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Text
} from "@chakra-ui/react";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import Layout from "../components/Layout";
import Head from "../components/Head";

import { Accordion } from "../components/Accordion";
import { CallToAction } from "../components/CallToAction";
import { Carousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";


const FaqPage: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query {
      page: mdx(
        frontmatter: {
          title: {
            eq: "FAQ"
          }
        }
      ) {
        frontmatter {
          seo_title
          seo_description
          heading
          subheading
          call_to_action_mobile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          call_to_action_desktop {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      faq: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "faq"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              question
              answer
            }
          }
        }
      }

      projects: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "project"
            }
            featured: {
              eq: true
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              project_type
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      services: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              order
            }
            slug
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              author_name
              author_title
              testimonial
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title={ data.page.frontmatter.seo_title }
        description={ data.page.frontmatter.seo_description }
      />

      <SectionWrapper
        background="background.aliceblue"
        padding="153px 0 138px"
      >
        <SectionContainer>
          <PageHero
            heading={ data.page.frontmatter.heading }
            description={ data.page.frontmatter.subheading }
            alignment="left"
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="42px 0 122px"
      >
        <SectionContainer>
          <Accordion
            nodes={ data.faq.edges }
            layout="faq"
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper>
        <CallToAction
          mobileImage={ data.page.frontmatter.call_to_action_mobile.childImageSharp.gatsbyImageData }
          desktopImage={ data.page.frontmatter.call_to_action_desktop.childImageSharp.gatsbyImageData }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="110px 0"
      >
        <Carousel
          nodes={ data.projects.edges }
          options={{
            loop: true
          }}
          template="project"
          heading="Featured Projects"
          headingAlignment="space-between"
          headingWidth={{
            base: '100%',
            lg: '600px'
          }}
          description="We're proud of the work we've done to help transform the Greater Toronto Area. Here are a few of the projects that have made their mark on the city."
          slug="/portfolio"
          buttonText="Explore More Projects"
        />
      </SectionWrapper>

      <SectionWrapper
        background="background.aliceblue"
        padding="110px 0"
      >
        <Carousel
          nodes={ data.testimonials.edges }
          options={{
            loop: true
          }}
          template="testimonial"
          heading={
            <>
              People <Text as="span" color="primary.900">love</Text> what we do
            </>
          }
          headingAlignment="space-between"
        />
      </SectionWrapper>
    </Layout>
  )
}


export default FaqPage;
